//====================================================
//  Function: Counter
//====================================================
function componentCounter() {
  if ($('.js-counter').length) {
    $('.js-counter .js-counter-item').each(function () {
      var $this = $(this),
        countTo = $this.attr('data-number')

      $({ countNum: $this.text() }).animate(
        {
          countNum: countTo,
        },

        {
          duration: 2000,
          easing: 'linear',
          step: function () {
            $this.text(numberWithCommas(Math.floor(this.countNum)))
          },
          complete: function () {
            $this.text(numberWithCommas(this.countNum))
          },
        },
      )
    })

    function numberWithCommas(n) {
      var parts = n.toString().split('.')
      return parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, '.')
    }
  }
}
