// --- GLOBAL VARIABLES --- //
var $window = $(window)
var $document = $(document)
var $header = $('.header')
var bpSmall = 771
var bpMedium = 992
var bpLarge = 1200
var classIsActive = 'is-active'
var classIsDisabled = 'is-disabled'
var classIsLast = 'is-last'
var classHasError = 'has-error'
var classIsLoading = 'is-loading'
var classIsOpened = 'is-opened'
var classIsSelected = 'is-selected'
var classIsCollapse = 'is-collapse'
var classIsAnimated = 'is-animated'
var $this = null
var $containers = $('html,body')

gsap.registerPlugin(ScrollTrigger)
let bodyScrollBar
let scrollContent
const select = (e) => document.querySelector(e)

// --- COMPONENTS, PARTS --- //
$(document).ready(function () {
  // ---  COMPONENTS --- //
  // Swiper
  componentSwiper()

  // Accordion
  componentAccordion()

  // Tabs
  componentTabs()

  // Navigation
  componentFullscreenNavigation()

  // Ajax
  componentAjaxLoad()

  if ($(window).width() > bpSmall) {
    // SmoothScrollbar
    // componentSmoothScrollbar()
  }

  // // AOS
  // componentAOS()

  // // ---  PART --- //

  // Scroll to
  partScrollTo()

  // Show Hidden
  partShowHidden()

  // Dropdown
  partDropdown()

  // SwitchPricing
  partSwitchPricing()
})

var resizeTimer

// --- SCROLL EVENT --- //
$(document).scroll(function () {
  if (resizeTimer) {
    window.cancelAnimationFrame(resizeTimer)
  }

  resizeTimer = window.requestAnimationFrame(function () {
    partScrollResize()
  })
})

// --- RESIZE EVENT --- //
$(window).resize(function () {
  if (resizeTimer) {
    window.cancelAnimationFrame(resizeTimer)
  }

  resizeTimer = window.requestAnimationFrame(function () {
    partScrollResize()
  })
})

// --- LOAD EVENT --- //
$(window).bind('load', function () {
  // Page Loader
  $('body').addClass(classIsActive)

  scrollInner = $('.scroll-content')

  // Animation
  componentAnimation()

  // ScrollResize
  partScrollResize()
})
