//====================================================
//  Function: Scroll & resize events
//====================================================
var windowScrollTop
var windowWidth

if ($('.js-navigation').length) {
  var navigation = $('.js-navigation')
}

if ($('.js-stats-section').length) {
  var statsSection = $('.js-stats-section')
  var statsOnce = false
  var statsTop
  var statsHeight
}

function partScrollResize() {
  // if ($(window).width() > bpSmall) {
  //   scrollContent = select('.scroll-content')

  //   ScrollTrigger.create({
  //     id: 'viewportScroller',
  //     trigger: scrollContent,
  //     start: 'top top-=100',
  //     end: 'bottom bottom-=200',
  //     toggleClass: {
  //       targets: '.js-navigation',
  //       className: 'is-scrolling',
  //     },
  //   })

  //   if ($('.js-stats-section').length) {
  //     ScrollTrigger.create({
  //       id: 'viewportScroller',
  //       trigger: '.js-stats-section',
  //       start: 'top top',
  //       end: 'top bottom',
  //       once: true,
  //       onEnter: ({ direction }) => componentCounter(),
  //     })
  //   }
  // } else {
  // }

  windowScrollTop = $(window).scrollTop()
  windowWidth = $(window).width()

  if ($('.js-navigation').length) {
    if (windowScrollTop > 1) {
      navigation.addClass('is-scrolling')
    } else {
      navigation.removeClass('is-scrolling')
    }
  }

  if ($('.js-stats-section').length) {
    if (statsOnce) {
      return false
    }
    statsTop = statsSection.offset().top
    statsHeight = statsSection.outerHeight()
    if (
      windowScrollTop > statsTop - 70 &&
      windowScrollTop < statsTop + statsHeight - 70
    ) {
      componentCounter()
      statsOnce = true
    }
  }
}
