/* ==========================================================================
 *  Function: Price switcher
 * ======================================================================= */
function partSwitchPricing() {
  if ($('.js-switch-price').length) {
    $('.js-switch-price').on('change', function () {
      if (this.checked) {
        $('.js-slider-pricing').removeClass('is-annualy')
        $('.js-slider-pricing').addClass('is-monthly')
      } else {
        $('.js-slider-pricing').removeClass('is-monthly')
        $('.js-slider-pricing').addClass('is-annualy')
      }
    })
  }
}
