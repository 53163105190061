//====================================================
//  Function: Animation
//====================================================
function componentAnimation() {
  // HERO HEADLINE
  if ($('.js-animate-headline').length) {
    let timelineHeadline = gsap.timeline({
      defaults: {
        duration: 0.5,
        ease: 'back.out(1.4)',
      },
    })

    var listOptions = {
      opacity: '0',
      transform: 'translate(0px, 100px) rotateZ(0deg) rotateX(0) scale(0.2)',
    }

    gsap.utils.toArray('.js-animate-headline-item').forEach((text) => {
      timelineHeadline.from(text, listOptions)
    })
  }

  // ABOUT SECTION

  if ($('.js-parallax-about-image').length) {
    gsap.to('.js-parallax-about-image', {
      yPercent: -60,
      ease: 'none',
      scrollTrigger: {
        trigger: '.js-parallax-about',
        // start: "top bottom", // the default values
        // end: "bottom top",
        scrub: true,
      },
    })
  }

  if ($('.js-parallax-about-dots').length) {
    gsap.to('.js-parallax-about-dots', {
      yPercent: 5,
      ease: 'none',
      scrollTrigger: {
        trigger: '.js-parallax-about',
        // start: "top bottom", // the default values
        // end: "bottom top",
        scrub: true,
      },
    })
  }

  if ($('.js-parallax-about-bg').length) {
    gsap.to('.js-parallax-about-bg', {
      yPercent: 100,
      ease: 'none',
      scrollTrigger: {
        trigger: '.js-parallax-about',
        // start: "top bottom", // the default values
        // end: "bottom top",
        scrub: true,
      },
    })
  }

  if ($('.js-parallax-about-text').length) {
    gsap.to('.js-parallax-about-text', {
      yPercent: -35,
      ease: 'none',
      scrollTrigger: {
        trigger: '.js-parallax-about',
        // start: "top bottom", // the default values
        // end: "bottom top",
        scrub: true,
      },
    })
  }

  // Partners icon
  if ($('.js-animate-partnership').length) {
    let timelinePartnership = gsap.timeline({
      defaults: {
        duration: 0.5,
        ease: 'back.out(1.4)',
      },
      scrollTrigger: {
        trigger: '.js-parallax-partnership',
        start: 'top bottom', // the default values
        end: 'top top',
        scrub: true,
        once: true,
      },
    })

    var listOptionsPartnership = {
      opacity: '0',
      transform: 'translate(0px, 40px) rotateZ(0deg) rotateX(0) scale(1)',
    }

    gsap.utils.toArray('.js-animate-partnership-item').forEach((text) => {
      timelinePartnership.from(text, listOptionsPartnership)
    })
  }
}
