//====================================================
//  Function: Slider
//====================================================
function componentSwiper() {
  reviewsTopSlider()
  reviewsBottomSlider()
  screenSlider()
  pricingSlider()
}

function reviewsTopSlider() {
  if ($('.js-slider-reviews-top').length) {
    var inst = $('.js-slider-reviews-top'),
      swiperBlock = inst.closest('.js-slider-group')

    var mySwiperTop = new Swiper(inst, {
      spaceBetween: 20,
      lazy: true,
      loop: true,
      observer: true,
      observeParents: true,
      speed: 1000,
      slidesPerView: 'auto',
      // allowTouchMove: false,
      // noSwiping: true,
      autoplay: {
        delay: 5000,
        disableOnInteraction: false,
        reverseDirection: true,
      },
      // freeMode: true,
      // freeModeMomentum: false,
      // freeModeMomentumBounce: false,
      // freeModeSticky: true,
      breakpoints: {
        200: {
          spaceBetween: 15,
          loopedSlides: 2,
        },
        576: {
          spaceBetween: 15,
          loopedSlides: 3,
        },
        768: {
          spaceBetween: 40,
          loopedSlides: 3,
        },
        992: {
          loopedSlides: 4,
          spaceBetween: 40,
        },
      },
    })
  }
}

function reviewsBottomSlider() {
  if ($('.js-slider-reviews-bottom').length) {
    var inst = $('.js-slider-reviews-bottom'),
      swiperBlock = inst.closest('.js-slider-group')

    var mySwiperTop = new Swiper(inst, {
      spaceBetween: 20,
      lazy: true,
      loop: true,
      observer: true,
      observeParents: true,
      speed: 1000,
      slidesPerView: 'auto',
      // allowTouchMove: false,
      // noSwiping: true,
      autoplay: {
        delay: 5000,
        disableOnInteraction: false,
      },
      // freeMode: true,
      // freeModeMomentum: false,
      // freeModeMomentumBounce: false,
      // freeModeSticky: true,
      breakpoints: {
        200: {
          spaceBetween: 15,
          loopedSlides: 2,
        },
        576: {
          spaceBetween: 15,
          loopedSlides: 3,
        },
        768: {
          spaceBetween: 40,
          loopedSlides: 3,
        },
        992: {
          loopedSlides: 4,
          spaceBetween: 40,
        },
      },
    })
  }
}

function screenSlider() {
  if ($('.js-slider-screen').length) {
    $('.js-slider-screen').each(function () {
      var inst = $(this),
        swiperBlock = inst.closest('.js-slider-group'),
        effect = inst.data('effect'),
        animationSpeed = parseInt(inst.data('animation'))

      if (effect === 'fade') {
        var effectType = 'fade'
      } else {
        var effectType = 'slide'
      }

      var mySwiper = new Swiper(inst, {
        spaceBetween: 0,
        lazy: true,
        loop: true,
        // observer: true,
        // observeParents: true,
        speed: 500,
        slidesPerView: 1,
        // effect: effectType,
        // fadeEffect: {
        //   crossFade: true,
        // },
        // allowTouchMove: false,
        // noSwiping: true,
        autoplay: {
          delay: animationSpeed,
          disableOnInteraction: false,
        },
        // freeMode: true,
        // freeModeMomentum: false,
        // freeModeMomentumBounce: false,
        // freeModeSticky: true,
      })
    })
  }
}

var pricingSwiper

function pricingSlider() {
  if ($('.js-slider-pricing').length) {
    var inst = $('.js-slider-pricing'),
      swiperBlock = inst.closest('.js-slider-group'),
      swiperPagination = swiperBlock.find('.js-swiper-pagination')

    pricingSwiper = new Swiper(inst, {
      spaceBetween: 20,
      lazy: true,
      loop: false,
      observer: true,
      observeParents: true,
      speed: 500,
      initialSlide: 2,
      centeredSlides: true,
      centeredSlidesBounds: true,
      slideToClickedSlide: true,
      // passiveListeners: false,
      // preventClicks: false,
      // preventClicksPropagation: false,
      pagination: {
        el: swiperPagination,
        clickable: true,
      },
      breakpoints: {
        200: {
          spaceBetween: 30,
          slidesPerView: 'auto',
        },
        576: {
          spaceBetween: 30,
          slidesPerView: 'auto',
        },
        768: {
          spaceBetween: 30,
          slidesPerView: 'auto',
        },
        992: {
          slidesPerView: 4,
          spaceBetween: 20,
        },
      },
    })

    pricingSwiper.on('transitionEnd', function () {
      var index = pricingSwiper.snapIndex + 1
      $('.js-tab-list-item.is-active').removeClass(classIsActive)
      $('.js-tab-list-item:nth-child(' + index + ')').addClass(classIsActive)
    })

    // pricingSwiper.on('reachEnd', function () {
    //   var index = pricingSwiper.activeIndex + 2
    //   console.log(index)
    //   $('.js-tab-list-item.is-active').removeClass(classIsActive)
    //   $('.js-tab-list-item:nth-child(' + index + ')').addClass(classIsActive)
    // })
  }
}
