//====================================================
//  Function: Smooth Scroolbar
//====================================================

var scrollInner
var windowWidth
var windowScrollTop

if ($('.js-slider-pricing').length) {
  var pricingSection = $('.js-pricing-section')
  var pricingSectionHeight
  var tabsGroup = $('.tabs-group')
}

function componentSmoothScrollbar() {
  if ($('#viewport').length) {
    bodyScrollBar = Scrollbar.init(document.querySelector('#viewport'), {
      damping: 0.07,
      alwaysShowTracks: true,
    })

    bodyScrollBar.track.xAxis.element.remove()

    // keep ScrollTrigger in sync with Smooth Scrollbar
    ScrollTrigger.scrollerProxy(document.body, {
      scrollTop(value) {
        if (arguments.length) {
          bodyScrollBar.scrollTop = value // setter
        }
        return bodyScrollBar.scrollTop // getter
      },
    })

    // when the smooth scroller updates, tell ScrollTrigger to update() too:
    bodyScrollBar.addListener(ScrollTrigger.update)
    // if ($('.js-slider-pricing').length) {
    //   bodyScrollBar.addListener(scrollTabs)

    //   bodyScrollBar.addListener(function (status) {
    //     var offset = status.offset
    //     tabsGroup.style.top = offset.y + 'px'
    //   })
    // }
  }
}

function scrollTabs() {
  windowScrollTop = bodyScrollBar.scrollTop
  windowWidth = scrollInner.width()

  if (windowWidth < bpSmall) {
    tabsTop = tabsGroup.offset().top
    pricingSectionHeight = pricingSection.outerHeight()

    // console.log(tabsTop - 80)
    console.log(windowScrollTop)
    console.log(tabsTop - 80)
    if (
      windowScrollTop > tabsTop - 80 &&
      windowScrollTop < pricingSectionHeight - 60
    ) {
      tabsGroup.addClass('is-fixed')
    } else {
      tabsGroup.removeClass('is-fixed')
    }
  }
}
