//====================================================
//  Function: Tabs
//====================================================
function componentTabs() {
  if ($('.js-tabs-group').length) {
    $('.js-tabs-group .js-tab-list-item').click(function () {
      var inst = $(this)
      var thisId = inst.attr('data-id')
      var thisTabs = $(this).closest('.js-tabs-group')
      var thisTabsActiveId = thisTabs
        .find('.js-tab-list-item.' + classIsActive + '')
        .attr('data-id')
      var thisIdIndex = inst.index()

      if (thisId == 'undefined') {
        return false
      }

      if (thisTabsActiveId === 'tab-premium') {
        var slideWidth = $('.js-slider-pricing .swiper-slide').width()
        var newTranslateLast = -((slideWidth + 30) * 2)
        $('.js-slider-pricing .swiper-wrapper').css(
          'transform',
          'translate3d(' + newTranslateLast + 'px' + ', 0px, 0px)',
        )
        $('.js-slider-pricing .swiper-wrapper').css(
          'transition-duration',
          '500ms',
        )
        thisTabs
          .find('.js-tab-list-item.' + classIsActive + '')
          .removeClass(classIsActive)
        $(this).closest('.js-tab-list-item').addClass(classIsActive)
        return false
      }

      if (!inst.hasClass(classIsActive)) {
        thisTabs
          .find('.js-tab-list-item.' + classIsActive + '')
          .removeClass(classIsActive)
        $(this).closest('.js-tab-list-item').addClass(classIsActive)
        if (thisIdIndex === 3) {
          var slideWidth = $('.js-slider-pricing .swiper-slide').width()

          var newTranslateLast = -((slideWidth + 30) * 3)

          $('.js-slider-pricing .swiper-wrapper').css(
            'transform',
            'translate3d(' + newTranslateLast + 'px' + ', 0px, 0px)',
          )
          $('.js-slider-pricing .swiper-wrapper').css(
            'transition-duration',
            '500ms',
          )
        } else {
          $('.js-slider-pricing .swiper-wrapper').css(
            'transition-duration',
            '0ms',
          )
          pricingSwiper.slideTo(thisIdIndex)
        }
      }
    })
  }
}
